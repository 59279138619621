import { apiClient } from '../apiClient/apiClient';
import { mapToOrder } from '../apiClient/apiClientMappers';
import { OrderRfqOrigin, OrderStatus } from '../apiClient/generated';
import { adjustLineItemQuantitiesFromWristIntegrationToRules } from '../components/utils/lineItemUtils';
import { Order, OrderSummary } from '../types/order.types';
import { OrderTypes } from '../context/OrderTypes';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { db } from './db';
import {
    getAndUpdateEditableOrders,
    getEditableOrderById,
    resetEditableOrderById,
} from './editableOrders';
import { getLastComment } from './utils/rfq';
import { getLatestRfq } from './utils/getLatestRfq';
import {
    createReceivedOrderFromApiOrder,
    deleteReceivedOrder,
} from '../hooks/useReceivedOrder';

async function processOrderSummary(orderSummary: OrderSummary) {
    const localOrder = await db.orders.get(orderSummary.orderId);

    // When an order changes status to non-editable, reset editable order if necessary
    const editableOrder = await getEditableOrderById(orderSummary.orderId);
    const isLocalEditableOrderAccidentallyPersist =
        orderSummary.status !== 3 &&
        editableOrder?.orderId === orderSummary?.orderId;

    if (isLocalEditableOrderAccidentallyPersist) {
        await resetEditableOrderById(orderSummary.orderId);
    }

    const isOrderUpToDate =
        localOrder?.lastModified.toString() ===
        orderSummary.lastModified.toString();
    if (isOrderUpToDate) {
        // Local order exists and is up-to-date
        return;
    }

    const response = await apiClient.getOrderById(orderSummary.orderId);

    const orderDetails = mapToOrder(response);
    if (!orderDetails) {
        return;
    }

     /**
     * If status is 5: Delete receival record, they can now start receival all over
     * If status is 6: Re-create receival record from API response and let user submit it once again
     * If status is >= 7: The receipt is updated from Garrets, local receival record is deleted
     */
    const statusTransition = localOrder?.status !== orderDetails.status;
    const transitionsToRedoReceival =
        statusTransition && orderDetails.status === OrderStatus.OrderForConfirmation;
    const transitionsToAmendReceival =
        statusTransition && orderDetails.status === OrderStatus.ReceiptInProgress;
    const transitionsToReceipt =
        statusTransition && orderDetails.status >= OrderStatus.Receipt;

    if (transitionsToReceipt || transitionsToRedoReceival || transitionsToAmendReceival) {
        await deleteReceivedOrder(orderDetails.orderId);
    }
    if (transitionsToAmendReceival) {
        await createReceivedOrderFromApiOrder(orderDetails);
    }

    const rfqsWithlineItemsWithLastCommentFromRfqFlow =
        orderDetails.rfqs.map((rfq) => ({
            ...rfq,
            lineItems: rfq.lineItems.map(
                (lineItem) => ({
                    ...lineItem,
                    lastCommentFromRfqFlow: getLastComment(orderDetails, lineItem),
                })
            ),
        }));

    const order: Order = {
        ...orderSummary,
        ...orderDetails,
        rfqs: rfqsWithlineItemsWithLastCommentFromRfqFlow,
    };

    const latestRfq = getLatestRfq(order);
    if (latestRfq?.origin === OrderRfqOrigin.WristIntegration) {
        // Round quantity to packSize if packSize doesn't match when RFQ is returned
        latestRfq.lineItems =
            adjustLineItemQuantitiesFromWristIntegrationToRules(
                latestRfq.lineItems
            );
    }

    await db.orders.put(order);
    await getAndUpdateEditableOrders();
    dataFlowEventHub.emit('orderChanged', order.orderId);
    dataFlowEventHub.emit('ongoingOrderChanged', order.type);
}

export const updateOrders = async (
    orderSummaries: OrderSummary[] | undefined,
) => {
    if (!orderSummaries?.length) {
        return;
    }

    await Promise.all(orderSummaries.map(orderSummary => processOrderSummary(orderSummary)));
};

export const getOrder = async (orderId?: number) => {
    if (orderId) {
        return db.orders.get(orderId);
    }
};

export const hasAwaitingActionOrderOfType = async (
    type: OrderTypes | undefined,
): Promise<boolean> => {
    if (!type) return false;
    const awaitingActionOrderCount = await db.orders
        .where('status')
        .equals(3)
        .and((order) => order.type.toLowerCase() === type.toLowerCase())
        .count();
    return awaitingActionOrderCount > 0;
};

export const getAwaitingActionOrderById = async (
    orderId: number,
): Promise<Order | undefined> => {
    return db.orders
        .where('orderId')
        .equals(orderId)
        .and((order) => order.status === OrderStatus.OrderForReview)
        .first();
};

export const hasCompletedOrderWithId = async (orderId: number) => {
    const order = await db.orders.get(orderId);
    return order?.status === OrderStatus.Receipt;
};

export const getOngoingOrderOfType = async (
    type: OrderTypes,
): Promise<Order | undefined> => {
    return db.orders
        .where('status')
        .between(0, 4, true, true)
        .and((order) => order.type.toLowerCase() === type.toLowerCase())
        .first();
};

import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import styles from './../ProductsList/ProductsList.module.css';
import { adjustLineItemValueToPackSizeAndUnit } from '../utils/lineItemUtils';

interface DecimalInputProps {
    value: string | number;
    onChange: (value: string | number) => void;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onBlurValueConverter?: (value: string | number) => number | string;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    hasError?: boolean;
    lightInput?: boolean;
    integer?: boolean;
    outlined?: boolean;
    textAlign?: 'left' | 'right' | 'center';
    extraClass?: string;
    width?: number;
    isCurrencyInput?: boolean;
    awaitingAction?: boolean;
}

export const DecimalInput: React.FC<DecimalInputProps> = ({
    value,
    onChange,
    onBlur,
    onBlurValueConverter,
    onFocus,
    disabled,
    hasError,
    lightInput,
    integer,
    outlined,
    textAlign = 'center',
    extraClass,
    width,
    isCurrencyInput,
    awaitingAction,
}) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const shouldPreventChange = (value: string) => {
        if (!isCurrencyInput) {
            return false;
        }

        const hasDecimalSeparator = value.includes('.') || value.includes(',');

        if (!hasDecimalSeparator) {
            return false;
        }

        const number = Math.floor(Number(value));

        let decimalDigits = '';

        if (value.includes('.')) {
            decimalDigits = value.replace(`${number}.`, '');
        }

        const hasMoreThanTwoDecimalDigits = decimalDigits.length > 2;

        return hasMoreThanTwoDecimalDigits;
    };

    const normalizeDecimalInputValue = (value: string) => {
        const digitsNumber = (value + '').replace('.', '').length;
        if (digitsNumber > 6) {
            // quantity has a maximum of 10 digits. So the biggest amount can be: 999999.9999 but backend automatically adds four zeroes to every number so we allowed user to type 6 digits that makes us sure it won't explode.
            return;
        }

        let normalizedValue: string | number = value.replace(/[^0-9\.,]*/g, '');

        // Replace decimal comma with dot
        normalizedValue = normalizedValue.replace(/,/g, '.');
        // Remove duplicate decimal dots
        if (normalizedValue.includes('.')) {
            const partsArray = normalizedValue.split('.');
            const [wholeNumber] = partsArray;
            partsArray.shift();
            normalizedValue = `${wholeNumber}.${partsArray.join('')}`;
        }

        if (shouldPreventChange(normalizedValue)) {
            return;
        }

        const intValue = adjustLineItemValueToPackSizeAndUnit(
            Number(normalizedValue),
            undefined,
            undefined,
        );

        if (integer) {
            setLocalValue(intValue);
            onChange(Number(intValue));
        } else {
            setLocalValue(normalizedValue);
            onChange(Number(normalizedValue));
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <input
            autoComplete={'off'}
            className={[
                extraClass ?? '',
                styles.amountInput,
                hasError ? styles.error : '',
                lightInput ? styles.lightInput : '',
                outlined ? styles.outlined : '',
                awaitingAction ? styles.awaitingAction : '',
            ]
                .join(' ')
                .trim()}
            style={{ textAlign, width }}
            data-testid="decimalInput"
            ref={inputRef}
            value={localValue}
            onChange={(e) => {
                const value = e.target.value;

                normalizeDecimalInputValue(value);
            }}
            // This can't be a number input, because decimal commas are removed
            // "automagically" from number inputs by the browser
            type="text"
            disabled={disabled}
            // This will show the numeric keyboard on touch devices
            inputMode="decimal"
            onFocus={(e) => {
                if (Number(localValue) === 0) {
                    setLocalValue('');
                }
                onFocus?.(e);
            }}
            onBlur={(e) => {
                if (localValue === '') {
                    setLocalValue('0');
                }

                if (onBlurValueConverter) {
                    const convertedValue = onBlurValueConverter(localValue);
                    setLocalValue(convertedValue);
                    onChange(convertedValue);
                }

                onBlur?.(e);
            }}
        />
    );
};

export default DecimalInput;

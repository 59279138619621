import { GarretsContactTooltip } from '../Tooltips/GarretsContactTooltip';
import styles from './MissingDeliveryPortHelper.module.css';
import { useFeatureFlags } from '../../context/useFeatureFlags';

export const MissingDeliveryPortHelper = () => {
    const { featureFlags } = useFeatureFlags();

    return (
        <p className={styles.content}>
            If your delivery port is missing, please contact your{' '}
            {featureFlags?.hideName ? (
                <span className={styles.blue}>Vessel Account Manager</span>
            ) : (
                <GarretsContactTooltip>
                    <span className={styles.blue}>Vessel Account Manager</span>
                </GarretsContactTooltip>
            )}
        </p>
    );
};


import styles from './VRRatesCards.module.css';
import BudgetFigure from '../../BudgetFigure/BudgetFigure';
import { currencyFormatter } from '../../utils/numberFormat';
import { useVesselMetadata } from '../../../hooks/useVesselMetadata';
import React from 'react';
import { DateBadge } from '../../DateBadge/DateBadge';
import { BudgetFigureWithTabs } from '../../BudgetFigure/BudgetFigureWithTabs';
import { useVesselVrRates } from '../../../hooks/useVesselVrRates';
import InfoIcon from '../../../public/gfx/uiIcons/blueInfoIcon.svg';
import Tooltip from '../../Tooltips/Tooltip';
import moment from 'moment';

export const VRRatesCards = () => {
    const {
        data: { vesselCurrency, dailyBudgetRateInVesselCurrency },
    } = useVesselMetadata();

    const { vesselVrRates } = useVesselVrRates();

    const vesselCurrencyFormatter = (value: string | number) =>
        currencyFormatter(value, vesselCurrency);

    const vrFigureEmptyState = (
        <div className={styles.row}>
            <p>N/A</p>
            <Tooltip
                tooltipTitle="We can’t calculate your VR yet."
                tooltipMessage="We currently don’t have enough data to display. As soon as you create orders and complete stocktaking within Gateway you’ll receive monthly VR rates."
            >
                <InfoIcon width="16" height="18" />
            </Tooltip>
        </div>
    );

    const vrAverages = {
        tab1: {
            tabName: '1M',
            figureValue:
                vesselVrRates?.lastMonthVrAverage ?? vrFigureEmptyState,
        },
        tab2: {
            tabName: 'YTD',
            figureValue: vesselVrRates?.yearToDateVrAverage ?? vrFigureEmptyState,
        },
        tab3: {
            tabName: '6M',
            figureValue: vesselVrRates?.lastSixMonthsVrAverage ?? vrFigureEmptyState,
        },
        tab4: {
            tabName: '1Y',
            figureValue: vesselVrRates?.lastYearVrAverage ?? vrFigureEmptyState,
        },
    };

    const closingStockDateToDisplay = moment(
        vesselVrRates?.lastClosingStockMonth,
        "YYYY-MM" 
    )
        .endOf('month')
        .toDate();

    return (
        <div className={styles.cardsContainer}>
            <div>
                <BudgetFigureWithTabs
                    figureTabs={vrAverages}
                    caption="VR Average"
                    formatter={vesselCurrencyFormatter}
                    white
                    fadedCaption
                />
            </div>
            <div>
                <BudgetFigure
                    figure={dailyBudgetRateInVesselCurrency}
                    caption="Budget Rate"
                    white
                    fadedCaption
                    formatter={vesselCurrencyFormatter}
                />
            </div>
            <div>
                <BudgetFigure
                    figure={
                        vesselVrRates?.closingStockCountValue ?? vrFigureEmptyState
                    }
                    caption={
                        <div className={styles.defaultFigureCaption}>
                            <p>Closing StockCount</p>
                            <DateBadge date={closingStockDateToDisplay} />
                        </div>
                    }
                    white
                    fadedCaption
                    formatter={vesselCurrencyFormatter}
                />
            </div>
        </div>
    );
};

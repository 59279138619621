import styles from './VRPerformanceTrendGraph.module.css';
import { useState, useMemo } from 'react';
import { useVesselVrRates } from '../../../hooks/useVesselVrRates';
import {
    adjustVesselVrRatesToDisplayOnTrendGraph,
    checkIfTrendDataIsEmpty
} from '../../../db/vesselVrRates';
import InfoIcon from '../../../public/gfx/uiIcons/blueInfoIcon.svg';
import Tooltip from '../../Tooltips/Tooltip';
import { VRPerformanceTrendGraph } from './VRPerformanceTrendGraph';
import moment from 'moment';

export const VrPerformanceTrendGraphCard = () => {
    const { vesselVrRates } = useVesselVrRates();

    const tabs = [
        { id: 'yearToDate', tabName: 'YTD' },
        { id: '6months', tabName: '6M' },
        { id: '1year', tabName: '1Y' },
    ];
    const [selectedTab, setSelectedTab] = useState('yearToDate');

    const vrRatesHistory = useMemo(() => {
        return adjustVesselVrRatesToDisplayOnTrendGraph(
            vesselVrRates?.historicVesselVrRate,
            'yearToDate'
        ) ?? [];
    }, [vesselVrRates]);

    const isVrRatesHistoryEmpty = useMemo(() => {
        return checkIfTrendDataIsEmpty(vrRatesHistory);
    }, [vrRatesHistory]);

    const graphData = useMemo(() => {
        switch (selectedTab) {
            case '6months':
                return adjustVesselVrRatesToDisplayOnTrendGraph(
                    vesselVrRates?.historicVesselVrRate,
                    'last6Months'
                );
            case '1year':
                return adjustVesselVrRatesToDisplayOnTrendGraph(
                    vesselVrRates?.historicVesselVrRate,
                    'last12Months'
                );
            case 'yearToDate':
                const currentYear = moment().year();
                return vrRatesHistory.filter(item =>
                    moment(item.month, 'MMM').year() === currentYear
                );
            default:
                return [];
        }
    }, [selectedTab, vesselVrRates, vrRatesHistory]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.rowTitle}>
                        <p className={styles.graphTitle}>
                            VR Performance Trend
                        </p>
                        {isVrRatesHistoryEmpty && (
                            <Tooltip
                                additionalTriggerStyles={styles.tooltipButton}
                                tooltipTitle="We can’t calculate your VR yet."
                                tooltipMessage="We currently don’t have enough data to display. As soon as you create orders and complete stocktaking within Gateway you’ll receive monthly VR rates."
                            >
                                <InfoIcon width="16" height="18" />
                            </Tooltip>
                        )}
                    </div>
                    <div className={styles.rowWithTabs}>
                        {tabs.map(tab => (
                            <button
                                key={tab.id}
                                className={`${styles.tab} ${selectedTab === tab.id ? styles.active : styles.inactive}`}
                                onClick={() => setSelectedTab(tab.id)}
                            >
                                {tab.tabName}
                            </button>
                        ))}
                    </div>
                </div>
                <div className={styles.rowTitle}>
                    <div className={`${styles.legendMarkup} ${styles.blue}`} />
                    <p className={styles.legendText}>Actual Rate</p>
                    <div className={`${styles.legendMarkup} ${styles.black} ${styles.legendGap}`} />
                    <p className={styles.legendText}>Budget Rate</p>
                </div>
                <div style={{ width: '100%', height: 250 }}>
                    <VRPerformanceTrendGraph historicVesselVrRates={graphData} />
                </div>
            </div>
        </div>
    );
};

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { debounce, throttle } from 'throttle-debounce';
import { ProductCategory } from '../components/ProductsList/ProductsList';
import { StocktakingProductCategoryToDisplay } from '../components/StocktakingProductsList/utils/stocktakingUtils';
import { Product } from '../types/order.types';

export const idPrefixForCategoryRowElement = 'productCategoryRow-';
export const idPrefixForProductRowElement = 'productRow-';

export const enum Page {
    receivalChecklist = 'receivalChecklist',
    stocktakingProductsList = 'stocktakingProductsList',
}

export const useCategoriesBarScrollFunctions = (
    productsSortedByCategories:
        | ProductCategory<Product>[]
        | StocktakingProductCategoryToDisplay[],
    stickyTableHeaderHeight: number,
    page: Page,
) => {
    const productListRef = useRef<HTMLDivElement | null>(null);
    const headerRef = useRef<HTMLDivElement | null>(null);
    const stickyTableHeadRef = useRef<HTMLDivElement | null>(null);

    const [categoriesBarsScrollLeft, setCategoriesBarsScrollLeft] = useState(0);
    const [categoriesPositionsUpdatesCounter, setCategoriesPositionsUpdatesCounter] = useState(0);
    const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
    const [stickyTableHeadHeight, setStickyTableHeadHeight] = useState<number | undefined>(stickyTableHeaderHeight);
    const [containerWidth, setContainerWidth] = useState<number | undefined>();
    const [isCategoriesBarSticky, setIsCategoriesBarSticky] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({
        categoryName: '',
        changedManually: false,
    });
    const [preventHighlightingVisibleCategory, setPreventHighlightingVisibleCategory] = useState(false);
    const [productListScrolledToTheEnd, setProductListScrolledToTheEnd] = useState(false);

    const forceUpdateCategoriesPositions = () => {
        setCategoriesPositionsUpdatesCounter((prev) => prev + 1);
    };

    const calculateAmountToScroll = (element: HTMLElement, stickyTableHeadHeight: number) => {
        if (page === Page.receivalChecklist) {
            return element.offsetTop - stickyTableHeadHeight;
        }
        if (page === Page.stocktakingProductsList) {
            if (isCategoriesBarSticky) {
                return element.offsetTop - 60 - stickyTableHeadHeight;
            }
            return element.offsetTop - 2 * stickyTableHeadHeight - 60;
        }
        return element.offsetTop;
    };

    const scrollToElement = (element: HTMLElement | null) => {
        if (!element || !productListRef.current || !stickyTableHeadHeight) return;

        const scrollHeight = calculateAmountToScroll(element, stickyTableHeadHeight);
        productListRef.current.scrollTo({
            top: scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollToCategory = (categoryName: string) => {
        const categoryRowEl = document.getElementById(`${idPrefixForCategoryRowElement}${categoryName}`);
        if (!categoryRowEl) return;

        window.requestAnimationFrame(() => {
            scrollToElement(categoryRowEl);
        });
    };

    const scrollToProduct = (itemNumber: string) => {
        const productRowEl = document.getElementById(`${idPrefixForProductRowElement}${itemNumber}`);
        scrollToElement(productRowEl);
    };

    const selectCategory = (categoryName: string, changedManually: boolean) => {
        scrollToCategory(categoryName);
        setSelectedCategory({ categoryName, changedManually });

        setPreventHighlightingVisibleCategory(true);
        setTimeout(() => {
            setPreventHighlightingVisibleCategory(false);
        }, 500);
    };

    useEffect(() => {
        if (!headerHeight || productListRef.current == null) return;

        const makeCategoriesBarSticky = throttle(100, () => {
            const productListScrollTop = productListRef.current?.scrollTop ?? 0;
            setIsCategoriesBarSticky(productListScrollTop > headerHeight);
        });

        productListRef.current.addEventListener('scroll', makeCategoriesBarSticky);

        return () => {
            productListRef.current?.removeEventListener('scroll', makeCategoriesBarSticky);
        };
    }, [headerHeight]);

    useEffect(() => {
        const getContainerWidth = throttle(100, () => {
            if (productListRef.current) {
                setContainerWidth(productListRef.current.clientWidth);
            }
        });

        getContainerWidth();
        window.addEventListener('resize', getContainerWidth);

        return () => {
            window.removeEventListener('resize', getContainerWidth);
        };
    }, [productListRef.current]);

    useEffect(() => {
        if (typeof ResizeObserver !== 'undefined') {
            const observer = new ResizeObserver(() => {
                if (stickyTableHeadRef.current) {
                    setStickyTableHeadHeight(stickyTableHeadRef.current.clientHeight);
                }
            });

            if (stickyTableHeadRef.current) {
                observer.observe(stickyTableHeadRef.current);
            }

            return () => {
                observer.disconnect();
            };
        } else {
            const handleResize = debounce(150, () => {
                if (stickyTableHeadRef.current) {
                    setStickyTableHeadHeight(stickyTableHeadRef.current.clientHeight);
                }
            });

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [stickyTableHeadRef.current]);

    useLayoutEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [headerRef.current]);

    useEffect(() => {
        if (productsSortedByCategories?.[0] && selectedCategory.categoryName === '') {
            setSelectedCategory({
                categoryName: productsSortedByCategories[0].categoryName,
                changedManually: false,
            });
        }
    }, [productsSortedByCategories]);

    useEffect(() => {
        if (preventHighlightingVisibleCategory) return;

        const getCategoryRowsElements = () => {
            return productsSortedByCategories
                .map((item) => {
                    const categoryRowEl = document.getElementById(`${idPrefixForCategoryRowElement}${item.categoryName}`);
                    return {
                        element: categoryRowEl,
                        offset: categoryRowEl?.offsetTop,
                        categoryName: item.categoryName,
                    };
                })
                .filter((item) => item.element !== null && item.offset !== undefined);
        };

        const elements = getCategoryRowsElements();

        const selectVisibleCategory = debounce(100, () => {
            if (!productListRef.current || elements.length === 0) return;

            const isProductListScrolledToTheEnd =
                productListRef.current.scrollTop + productListRef.current.offsetHeight >
                productListRef.current.scrollHeight - 100;

            if (isProductListScrolledToTheEnd) {
                setProductListScrolledToTheEnd(true);
            } else {
                setProductListScrolledToTheEnd(false);

                const breakPoint = productListRef.current.scrollTop + (stickyTableHeadHeight ?? 142);

                const validElements = elements.filter((el) => el.offset !== undefined);

                if (validElements.length === 0) return;

                const closestElement = validElements.reduce((prev, curr) => {
                    const prevOffset = prev.offset ?? Infinity;
                    const currOffset = curr.offset ?? Infinity;

                    return Math.abs(currOffset - breakPoint) < Math.abs(prevOffset - breakPoint)
                        ? curr
                        : prev;
                });

                setSelectedCategory({
                    categoryName: closestElement.categoryName,
                    changedManually: false,
                });
            }
        });

        productListRef.current?.addEventListener('scroll', selectVisibleCategory);

        return () => {
            productListRef.current?.removeEventListener('scroll', selectVisibleCategory);
        };
    }, [
        productsSortedByCategories,
        categoriesPositionsUpdatesCounter,
        preventHighlightingVisibleCategory,
        productListScrolledToTheEnd,
    ]);

    const { query } = useRouter();

    useEffect(() => {
        const queryItemNumber = query?.itemNumber;
        if (queryItemNumber && page === Page.receivalChecklist) {
            setTimeout(() => scrollToProduct(String(queryItemNumber)), 100);
        }
        
    }, [query, page]);

    return {
        productListRef,
        stickyTableHeadRef,
        isCategoriesBarSticky,
        containerWidth,
        stickyTableHeadHeight,
        headerRef,
        selectCategory,
        selectedCategory,
        productListScrolledToTheEnd,
        categoriesBarsScrollLeft,
        setCategoriesBarsScrollLeft,
        forceUpdateCategoriesPositions,
    };
};

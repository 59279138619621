import ChatMessageHeader from '../ChatBubble/ChatMessageHeader';
import styles from '../ProductsList/ProductDetailsModal.module.css';
import modalStyles from '../Modal/Modal.module.css';
import Button from '../Button/Button';
import { LineItem } from '../../types/order.types';
import { OrderRfqOrigin } from '../../apiClient/generated';
import { useEffect, useRef } from 'react';
import { isTouchDevice } from '../utils/isTouchDevice';
import { LimitedTextArea } from '../LimitedTextArea/LimitedTextArea';
import { db } from '../../db/db';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface AddCommentToLineItemProps {
    commentContent: string;
    setCommentContent: React.Dispatch<React.SetStateAction<string>>;
    addComment: () => void;
    inputTitle: string;
    isLocked?: boolean;
    viewOnly?: boolean;
    lineItem?: LineItem;
    customHeader?: JSX.Element;
    onBack?: () => void;
    customActionButtonCaption?: string;
    customBackButtonCaption?: string;
    cashPurchaseItem?: boolean;
    closeCommentView?: () => void;
}

export const AddCommentToLineItem: React.FC<AddCommentToLineItemProps> = ({
    commentContent,
    addComment,
    setCommentContent,
    isLocked,
    viewOnly,
    lineItem,
    inputTitle,
    customHeader,
    onBack,
    customActionButtonCaption,
    customBackButtonCaption,
    cashPurchaseItem,
    closeCommentView
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const { featureFlags } = useFeatureFlags();

    useEffect(() => {
        const focusTextArea = () => {
            setTimeout(() => {
                if (textAreaRef.current) {
                    textAreaRef.current.focus();
                }
            }, 100);
        };

        if (isTouchDevice()) {
            if (!commentContent || commentContent.length === 0) {
                focusTextArea();
            }
        } else {
            focusTextArea();
        }
    }, [textAreaRef]);
    
    return (
        <>
            <div className={styles.modalContent}>
                {customHeader ? (
                    customHeader
                ) : (
                    <h2 className={`${modalStyles.headerText}`}>
                        {!isLocked && !viewOnly
                            ? 'Add a comment'
                            : 'Attached comment'}
                    </h2>
                )}
                <div className={styles.spacer} />
                {!isLocked && !viewOnly && (
                    <p
                        className={[
                            modalStyles.text,
                            modalStyles.upperCase,
                        ].join(' ')}
                    >
                        {inputTitle}
                    </p>
                )}
                {!cashPurchaseItem && viewOnly && (
                    <div className={styles.commentAuthor}>
                        <ChatMessageHeader
                            avatarUrl={featureFlags?.hideName ? undefined : "/gfx/generateLogo.svg"}
                            displayName={
                                featureFlags?.hideName 
                                ? "Comment" 
                                : (lineItem?.lastCommentFromRfqFlow?.origin === OrderRfqOrigin.App ? 'You' : 'Garrets')
                            }
                            createdAt={lineItem?.lastCommentFromRfqFlow?.createdAt ?? new Date()}
                        />
                    </div>
                )}
                <LimitedTextArea
                    forwardRef={textAreaRef}
                    name="commentInput"
                    className={modalStyles.commentInput}
                    value={
                        lineItem?.lastCommentFromRfqFlow?.comment ??
                        commentContent
                    }
                    disabled={isLocked || viewOnly}
                    onChange={(value) => setCommentContent(value ?? '')}
                    characterLimit={db.defaults.axOrderLineStringFieldLimit}
                    readOnly={viewOnly}
                />
            </div>
            <div className={modalStyles.squareActionButton}>
                {onBack && (
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text={customBackButtonCaption ?? 'Back'}
                            onClick={onBack}
                            secondary
                        />
                    </div>
                )}
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={
                            isLocked || viewOnly
                                ? 'Close'
                                : customActionButtonCaption ?? 'Add'
                        }
                        onClick={() => {
                            if (isLocked || viewOnly) {
                                closeCommentView?.(); 
                            } else {
                                addComment();
                            }
                        }}
                        primary
                    />
                </div>
            </div>
        </>
    );
};

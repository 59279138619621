import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styles from './SideNavigation.module.css';
import Dashboard from '../../public/gfx/menuIcons/dashboard.svg';
import AwaitingActionOrders from '../../public/gfx/menuIcons/awaitingActionOrder.svg';
import Orders from '../../public/gfx/menuIcons/orders.svg';
import Claims from '../../public/gfx/menuIcons/claims.svg';
import UserProfile from '../../public/gfx/menuIcons/userProfile.svg';
import ManningIcon from '../../public/gfx/menuIcons/manningIcon.svg';
import ManningIconAwaitingAction from '../../public/gfx/menuIcons/manningIconAwaitingAction.svg';
import StocktakingIcon from '../../public/gfx/menuIcons/stocktakingIcon.svg';
import CondemnIcon from '../../public/gfx/menuIcons/condemnBin.svg';
import StocktakingIconAwaitingAction from '../../public/gfx/menuIcons/stocktakingIconAwaitingAction.svg';
import GenerateLogo from '../../public/gfx/generateLogo.svg';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import AppSettingsModal from '../AppSettingsModal/AppSettingsModal';
import { useOrders } from '../../hooks/useOrders';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import { useManningReports } from '../../hooks/useManningReports';
import { useStocktakingReports } from '../../hooks/useStocktakingReports';
import { NewOrderButton } from '../NewOrderButton/NewOrderButton';

type Props = object;

const SideNavigation: React.FC<Props> = () => {
    const { featureFlags } = useFeatureFlags();
    const { data: vesselMetadata } = useVesselMetadata();
    const { isAnyOrderInAwaitingAction } = useOrders();
    const { isAnyManningReportInAwaitingAction } = useManningReports({
        withReopen: featureFlags?.manningReopen,
    });
    const { isAnyStocktakingReportInAwaitingAction } = useStocktakingReports();

    const { asPath, replace } = useRouter();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const NavLink = (
        label: string,
        baseHref: string,
        icon: React.VFC<React.SVGProps<SVGSVGElement>>,
        onClick?: () => void,
        isAnyOrderInAwaitingAction?: boolean,
    ) => {
        const Icon = icon;

        let linkStyle = styles.navItem;
        const isActive = asPath.startsWith(baseHref);
        if (isActive) {
            linkStyle += ` ${styles.navItemActive}`;
        }

        const renderIcon = () => {
            return (
                <Icon
                    width={isAnyOrderInAwaitingAction ? '25' : '24'}
                    height={isAnyOrderInAwaitingAction ? '26' : '24'}
                    stroke={isActive ? '#020C16' : '#ACB4BB'}
                />
            );
        };

        const handleClick = () => {
            if (onClick) {
                onClick();
            }
            closeSidebar();
        };

        return (
            <button className={linkStyle} onClick={handleClick}>
                {renderIcon()}
                <span className={styles.navLabel}>{label}</span>
            </button>
        );
    };

    const [isAppSettingsModalOpen, setIsAppSettingsModalOpen] = useState(false);

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const openSidebar = () => {
        setIsSidebarOpen(true);
    };

    const closeAppSettingsModal = () => {
        setIsAppSettingsModalOpen(false);
    };

    const openAppSettingsModal = () => {
        setIsAppSettingsModalOpen(true);
    };

    const orderIcon = isAnyOrderInAwaitingAction ? AwaitingActionOrders : Orders;

    const manningIcon = isAnyManningReportInAwaitingAction
        ? ManningIconAwaitingAction
        : ManningIcon;

    const stocktakingIcon = isAnyStocktakingReportInAwaitingAction
        ? StocktakingIconAwaitingAction
        : StocktakingIcon;

    const isReportSectionAvailable =
        Boolean(featureFlags?.manning) || Boolean(featureFlags?.stocktaking);

    return (
        <>
            <AppSettingsModal
                isOpen={isAppSettingsModalOpen}
                closeAppSettingsModal={closeAppSettingsModal}
            />
            <div
                className={`${styles.navbar} ${isSidebarOpen ? styles.hidden : ''}`}
                data-testid="sideNav"
            >
                <nav>
                    <h3 className={styles.category}>Menu</h3>
                    {NavLink('Dashboard', '/dashboard', Dashboard, () => replace('/dashboard'))}
                    {NavLink(
                        'Orders',
                        '/order',
                        orderIcon,
                        () => replace('/order'),
                        isAnyOrderInAwaitingAction,
                    )}
                    {Boolean(featureFlags?.claims) &&
                        NavLink('Claims', '/claims', Claims, () => replace('/claims'))}
                    {Boolean(featureFlags?.condemnStandalone) &&
                        NavLink('Condemn', '/condemn', CondemnIcon, () => replace('/condemn'))}
                    {isReportSectionAvailable && <h3 className={styles.category}>Report</h3>}
                    {Boolean(featureFlags?.manning) &&
                        NavLink(
                            'Manning',
                            '/manning',
                            manningIcon,
                            () => replace('/manning'),
                            isAnyOrderInAwaitingAction,
                        )}
                    {Boolean(featureFlags?.stocktaking) &&
                        NavLink(
                            'Inventory',
                            '/stocktaking',
                            stocktakingIcon,
                            () => replace('/stocktaking'),
                            isAnyOrderInAwaitingAction,
                        )}
                </nav>
                <h3 className={styles.category}>Quick Actions</h3>
                <div className={styles.quickActionsContainer}>
                    <NewOrderButton transparent onClick={closeSidebar} />
                </div>
                <div className={styles.navBottom}>
                    <p
                        className={styles.navItem}
                        onClick={() => {
                            openAppSettingsModal();
                            closeSidebar();
                        }}
                    >
                        <UserProfile width="22" height="22" stroke="#020C16" />
                        <span className={`${styles.navLabel} ${styles.userProfile}`}>
                            Imo No. {vesselMetadata.imoNo}
                        </span>
                    </p>
                </div>

                <div className={styles.navFooter}>
                    <div className={styles.logo}>
                        {featureFlags?.hideName ? (
                            <>
                                <div style={{ height: '48px' }}></div>
                            </>
                        ) : (
                            <>
                                {' '}
                                <GenerateLogo width="48" height="46" />
                            </>
                        )}
                    </div>
                    <p className={styles.appVersionLabel}>
                        App Version {process.env.NEXT_PUBLIC_REACT_APP_VERSION}
                    </p>
                      {/* Link to Van Hulle terms of use PDF */}
                    <div className={styles.termsOfUse}>
                        {featureFlags?.hideName ? (
                            <a
                                href="/pdfs/GatewayTermsofUse.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of use
                            </a>
                        ) : (
                            <a
                                href="/pdfs/termsOfUse.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of use
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className={`${styles.hamburgerContainer} ${isSidebarOpen ? styles.hidden : ''}`}>
                <button className={styles.hamburgerButton} onClick={openSidebar}>
                    &#9776; {/* Hamburger icon */}
                </button>
            </div>
            <div className={`${styles.dialogContent} ${isSidebarOpen ? styles.open : ''}`}>
                <button className={styles.closeButton} onClick={closeSidebar}>
                    &times; {/* Close icon */}
                </button>
                <nav>
                    <h3 className={styles.category}>Menu</h3>
                    {NavLink('Dashboard', '/dashboard', Dashboard, () => replace('/dashboard'))}
                    {NavLink(
                        'Orders',
                        '/order',
                        orderIcon,
                        () => replace('/order'),
                        isAnyOrderInAwaitingAction,
                    )}
                    {Boolean(featureFlags?.claims) &&
                        NavLink('Claims', '/claims', Claims, () => replace('/claims'))}
                    {Boolean(featureFlags?.condemnStandalone) &&
                        NavLink('Condemn', '/condemn', CondemnIcon, () => replace('/condemn'))}
                    {isReportSectionAvailable && <h3 className={styles.category}>Report</h3>}
                    {Boolean(featureFlags?.manning) &&
                        NavLink(
                            'Manning',
                            '/manning',
                            manningIcon,
                            () => replace('/manning'),
                            isAnyOrderInAwaitingAction,
                        )}
                    {Boolean(featureFlags?.stocktaking) &&
                        NavLink(
                            'Inventory',
                            '/stocktaking',
                            stocktakingIcon,
                            () => replace('/stocktaking'),
                            isAnyOrderInAwaitingAction,
                        )}
                </nav>
                <h3 className={styles.category}>Quick Actions</h3>
                <div className={styles.quickActionsContainer}>
                    <NewOrderButton
                        transparent
                        onClick={() => {
                            closeSidebar();
                        }}
                    />
                </div>
                <div className={styles.navBottom}>
                    <p
                        className={styles.navItem}
                        onClick={() => {
                            closeSidebar();
                            openAppSettingsModal();
                        }}
                    >
                        <UserProfile width="22" height="22" stroke="#020C16" />
                        <span className={`${styles.navLabel} ${styles.userProfile}`}>
                            Imo No. {vesselMetadata.imoNo}
                        </span>
                    </p>
                </div>
                <hr className="hr" />
                <div className={styles.navFooter}>
                    <div className={styles.logo}>
                        <GenerateLogo width="48" height="46" />
                    </div>
                    <p className={styles.appVersionLabel}>
                        App Version {process.env.NEXT_PUBLIC_REACT_APP_VERSION}
                    </p>
                    <div className={styles.termsOfUse}>
                        <a href="/pdfs/termsOfUse.pdf" target="_blank" rel="noopener noreferrer">
                            Terms of use
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SideNavigation;

import Link from 'next/link';
import React from 'react';
import { Field, FieldProps } from './Field';
import styles from './List.module.css';
import { LoadingState } from './LoadingState';
import DoubleCheckmarkGreen from '../../public/gfx/uiIcons/doubleCheckmarkGreen.svg';
import DoubleCheckmarkBlue from '../../public/gfx/uiIcons/doubleCheckmarkBlue.svg';
import { StocktakingItem } from '../../types/stocktaking.types';
export enum SeparatorMode {
    betweenEach = 'between-each',
    beforeEach = 'before-each',
}

export enum ActiveColor {
    black = 'black',
    green = 'green',
}

interface Category {
    categoryName: string;
    products: StocktakingItem[];
}

interface ListProps<Data> {
    data: Data[];
    children:
        | React.ReactElement<FieldProps<Data>, typeof Field>
        | null
        | (React.ReactElement<FieldProps<Data>, typeof Field> | null)[];
    idSource?: keyof Data;
    getRowLink?: (record: Data) => string | undefined;
    onRowClick?: (record: Data) => void;
    getIsRowActive?: (record: Data) => boolean;
    getIsLineItemReceived?: (record: Data) => {
        isReceived: boolean;
        showConfirmed: boolean;
    };
    getAddedElementId?: (record: Data) => {
        addedElementId: number;
        elementId: number;
    };
    applySelectableRowStyle?: boolean;
    showHeader?: boolean;
    separatorMode?: SeparatorMode;
    rowElementIdPrefix?: string;
    emptyState?: JSX.Element;
    greyHeaderBackground?: boolean;
    isValidating?: boolean;
    additionalHeaderElement?: JSX.Element;
    activeRowColor?: ActiveColor;
    getIsRowDisabled?: (record: Data) => boolean;
    endingRowIcon?: (record: Data) => JSX.Element;
    showConfirmed?: boolean;
    category?: Category;
    openModal?: (category: Category) => void;
    customClassName?: string;
    setIsConfirmationModalOpen?: () => void;
    globalSearch?: boolean;
    awaitingAction?: boolean;
}

//eslint-disable-next-line
export function List<Data extends Record<string, any>>({
    data,
    children,
    idSource,
    getRowLink,
    onRowClick,
    getIsRowActive,
    getIsLineItemReceived,
    applySelectableRowStyle,
    showHeader = true,
    separatorMode = SeparatorMode.beforeEach,
    rowElementIdPrefix,
    emptyState,
    getAddedElementId,
    greyHeaderBackground = false,
    isValidating,
    additionalHeaderElement,
    activeRowColor = ActiveColor.black,
    getIsRowDisabled,
    endingRowIcon,
    showConfirmed,
    category,
    customClassName,
    setIsConfirmationModalOpen,
    globalSearch,
    awaitingAction
}: ListProps<Data>) {
    const header = () => (
        <div
            className={[
                styles.rowTitle,
                data.length === 0 ? styles.withBorder : '',
                greyHeaderBackground ? styles.withBackground : '',
            ].join(' ')}
            role="row"
        >
            {additionalHeaderElement && <>{additionalHeaderElement}</>}
            {React.Children.map(children, (child) => {
                if (
                    !React.isValidElement<FieldProps<Data>>(child) ||
                    child.type === React.Fragment
                ) {
                    return <></>;
                }

                return (
                    <p
                        className={`${styles.rowTitlesText} ${
                            child.props.iconOnly ? styles.iconOnly : ''
                        } ${child.props.boldTitle ? styles.bold : ''}`}
                        style={{
                            flex: child.props.customWidth,
                            minWidth: child.props.minWidth,
                        }}
                        role="columnheader"
                    >
                        {child.props.title || child.props.source}
                    </p>
                );
            })}

            {category && !awaitingAction && (
                <button 
                    className={`${styles.confirmAllButton} ${globalSearch ? styles.hiddenButton : ''}`} 
                    onClick={setIsConfirmationModalOpen}
                >
                    {showConfirmed ? <DoubleCheckmarkGreen /> : <DoubleCheckmarkBlue />}
                </button>
            )}
        </div>
    );

    const columns = (record: Data) =>
        React.Children.map(children, (child) => {
            if (!React.isValidElement<FieldProps<Data>>(child)) {
                return <></>;
            }
            return React.cloneElement<FieldProps<Data>>(child, {
                ...child.props,
                record,
                key: idSource ? record[idSource] : record.id,
            });
        });

    return (
        <div role="table" className={customClassName}>
            {showHeader && header()}
            {isValidating && data.length === 0 && <LoadingState />}
            {!isValidating && data.length === 0 && emptyState}
            {data.map((record) => {
                const rowLink = getRowLink?.(record);
                if (rowLink) {
                    return (
                        <span role="row" key={idSource ? record[idSource] : record.id}>
                            <Link href={rowLink} passHref>
                                <a className={styles.row}>{columns(record)}</a>
                            </Link>
                        </span>
                    );
                }

                const rowActive = getIsRowActive?.(record);
                const rowDisabled = getIsRowDisabled?.(record);
                const { isReceived, showConfirmed } = getIsLineItemReceived?.(record) ?? {};
                const { addedElementId, elementId } = getAddedElementId?.(record) ?? {};
                const rowClickable = Boolean(onRowClick);
                const rowClasses = [styles.row];
                if (rowActive && activeRowColor === ActiveColor.black) {
                    rowClasses.push(styles.active);
                }
                if (rowActive && activeRowColor === ActiveColor.green) {
                    rowClasses.push(styles.activeGreen);
                }
                if (rowClickable && applySelectableRowStyle) {
                    rowClasses.push(styles.clickable);
                }
                if (separatorMode === SeparatorMode.betweenEach) {
                    rowClasses.push(styles.separatorBetween);
                }
                if (isReceived) {
                    rowClasses.push(styles.receivedLineItem);
                    if (!showConfirmed) {
                        rowClasses.push(styles.hidenReceivedLineItem);
                    }
                }
                if (addedElementId) {
                    if (addedElementId === elementId) {
                        rowClasses.push(styles.addedLineItem);
                    }
                }
                if (rowDisabled) {
                    rowClasses.push(styles.rowDisabled);
                }

                return (
                    <>
                        {endingRowIcon && (
                            <div className={styles.endingRowsIcon}>{endingRowIcon?.(record)}</div>
                        )}
                        <span
                            id={
                                rowElementIdPrefix
                                    ? `${rowElementIdPrefix}${
                                          idSource ? record[idSource].toString() : undefined
                                      }`
                                    : undefined
                            }
                            className={rowClasses.join(' ')}
                            key={idSource ? record[idSource] : record.id}
                            role="row"
                            onClick={() => onRowClick?.(record)}
                            tabIndex={rowClickable ? 0 : undefined}
                            data-testid="listRow"
                        >
                            {columns(record)}
                        </span>
                    </>
                );
            })}
        </div>
    );
}
